<template>
    
    <div>

          <v-card class="halfFillHeader" elevation = "0">

            <v-card-text class="d-flex white--text align-center justify-center viewTitle">
                Dashboard
            </v-card-text>

            <div class="d-flex align-center justify-center">
              <v-btn style="background-color: white!important;" disabled elevation="0" fab class="portmanfab portmanLogo">
              <v-img
                :src="require('@/assets/images/logos/portman.png')"
                  max-height = "80px"
                  contain
              ></v-img>
            </v-btn>
            </div>

          </v-card>
                  
          <v-card  class="up25" elevation = "0">

            <v-card-text>
         
                    <p class="d-flex align-center justify-center viewTitle">Welcome {{  userFullName }}</p>
         
                    <component class = "mb-6"
                               :lastSubmissionDate =  "lastSubmissionDate"
                               :deadline           =  "generateDeadlineText"  
                               :is                 =  "lastSubmissionStatus">
                    </component>
               

                    <v-btn
                      block
                      :color="this.store.colors.darkGold"
                      class="lightGoldBorder white--text mb-6"
                      @click = "submitHoursClicked"
                    >Submit {{hoursOrDaysText}}
                    </v-btn>

                    <v-btn 
                        block
                        :color="this.store.colors.lightGold"
                        class="darkGoldBorder white--text"
                        @click='viewPastSubmissionsClicked'
                      >View Past Submissions
                    </v-btn> 

            </v-card-text>
         </v-card>
        
    </div>
      
</template>

<script>

import { store  } from '../store/store'

import  submissionAccepted    from '@/components/SubmissionStatus/submissionAccepted.vue';
import  submissionApproved    from '@/components/SubmissionStatus/submissionApproved.vue';
import  submissionArchived    from '@/components/SubmissionStatus/submissionArchived.vue';
import  submissionPending     from '@/components/SubmissionStatus/submissionPending.vue';
import  submissionOverdue     from '@/components/SubmissionStatus/submissionOverdue.vue';
import  submissionDeclined    from '@/components/SubmissionStatus/submissionDeclined.vue';
import  submissionNoRecords   from '@/components/SubmissionStatus/submissionNoRecords.vue';



export default {

    name        : 'Dashboard',
    components  : {submissionOverdue, submissionDeclined,submissionAccepted, submissionPending, submissionNoRecords,submissionApproved,submissionArchived},
    
    mounted() {
        console.log("Dashboard mounted")
    },

    beforeDestroy() {
        console.log("Dashboard before destroy")
    },
    
    computed : {

      hoursOrDaysText(){
              let res = (this.store.user.dayRateCandidate) ? "Days" : "Hours";
              return res;
      },

      lastSubmissionStatus: function () {

        if (store === null)
            return null;

        if (store.submissions.length === 0 ) { 
            return ("submissionNoRecords")
        }

        if  ( store.overdue ) {
             return ("submissionOverdue")
        }
                    
        //////////////////////////////////////////
        // lets look at the last submission //////
        ////////////////////////////////////////// 
        let latestSubmission = store.submissions[0]; 
        let response         = null;

        if (latestSubmission.status      === "declined")      { response  =  "submissionDeclined" }
        else if (latestSubmission.status === "accepted")      { response  =  "submissionAccepted" }
        else if (latestSubmission.status === "pending")       { response  =  "submissionPending"  }
        else if (latestSubmission.status === "approved")      { response  =  "submissionApproved" }
        else if (latestSubmission.status === "archived")      { response  =  "submissionArchived" }

        return response;

      },

      lastSubmissionDate  : function () {

            if (store.submissions.length === 0 ) { 
                return null;
            }
            
            let latestSubmission     = store.submissions[0];
            let date                 = new Date( latestSubmission["created_at"] );
            let intlDateObj          = new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'short', timeZone: 'Europe/London'});
            let gbTime               = intlDateObj.format(date);
            return gbTime;
            
      },

      nextDeadline : function () {

          if ( this.store.deadLine ) {

              let     date         = new Date( this.store.deadLine );
              let     intlDateObj  = new Intl.DateTimeFormat('en-GB', { dateStyle: 'full'});
              let     gbTime      = intlDateObj.format(date);
              return  gbTime;

          } else 
            return null;
      },

      userFullName : function() {
        return ( this.store.user) ? this.store.user.fullName : ''
      },

      generateDeadlineText: function () {

        if (store === null)
            return;

        if (store.userState === null )
             return  
              
             
         if (store.isHoursOpen )
              return ( store.userState.hoursSubmitted === 'no') ? "You can now upload your work" : null;
         else     
              return ( "You can submit from Friday PM to Tuesday AM" );
          
       //    return (store.isHoursOpen ) ? "You can now upload latest hours" : "You can submit from Friday PM to Tuesday AM"; 


      }

    },

    data () {
      return {
        store,
      }
    },

    methods    : {

      isOverdue() {

        const today                   =  new Date();
        const latestSubmission        =  store.submissions[0];
        const lastSubmission          =  new Date( latestSubmission["created_at"] );

        var   difference              =  today - lastSubmission;
        var   daysSinceLastSubmission = Math.floor((difference)/(24*3600*1000));
        
        if   ( daysSinceLastSubmission > 7 )
              return true;
        else
             return false;
    
      },

      submitHoursClicked(){
         this.store.bottomNavigation = 1;
         this.$router.push("/hours");
      },
 
      viewPastSubmissionsClicked() {
        this.store.bottomNavigation = 2;
        this.$router.push("/history");
      }


    }

}

</script>


<style scoped>
    .dashedBorder { 
                border: dashed 4px #DFC049 !important;
                border-radius: 15px !important;
              }

</style>
import { reactive } from 'vue'

export const store = reactive({

    user                                : null, 
    userState                           : null,
    submissions                         : [],
    settings                            : [],

    deadLine                            : null,                                          
    refreshLoading                      : false,
    overdue                             : false,
    hoursSubmitted                      : false,
    isHoursOpen                         : false,
               
    appDates                            : { 
                                            weekEndingISO:   "",
                                            weekEndingLong:  "",
                                          },
    
    toastDialog                         : { 
                                          "show"       : false,
                                          "title"      : "Portman Hours",
                                          "subtext"    : "",
                                          "text"       : ""
    },
    
    colors                              : {
                                            darkGold  : '#B9802B',
                                            lightGold : '#DFC049'
                                          },
    
    bottomNavigation                    : 0,
    screenSize                          : { height : 0, width : 0}
    
})
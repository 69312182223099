<template>

      <div>

        <v-card class="halfFillHeader" elevation = "0">

          <v-card-text class="d-flex white--text align-center justify-center viewTitle">
                Portman Hours - Ver: {{appVersion}}
          </v-card-text>
          
          <div class="d-flex align-center justify-center">
               
                 <v-btn style="background-color: white!important;" :disabled = "true" elevation="0" fab class="portmanfab portmanLogo">
                   <v-img
                     :src="require('@/assets/images/logos/portman.png')"
                     max-height = "80px"
                     contain
                  ></v-img>

             </v-btn>
          </div>
        
        </v-card>
      
        <v-card elevation = "0" style="padding: 20px;  background: white">

            <v-form ref="signonForm" v-model="isValid">

                <v-text-field
                  solo
                  rounded
                  v-model="credential"
                  :rules="[ v => !!v || 'email is required' ]"
                  required
                  label="your username or email"
                  placeholder="john | john@example.com"
                ></v-text-field>

                <v-text-field
                  solo
                  rounded
                  v-model ="password"
                  :rules="[ v => !!v || 'password is required' ]"
                  required
                  label ="Password"
                  :append-icon   = "value ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append  = "() => (value = !value)"
                  :type           = "value ? 'password' : 'text'"
                ></v-text-field>

                <div class="d-flex align-center justify-center">
                  <v-btn
                    :loading  = "loading"
                    color='#b9802b'
                    class="lightGoldBorder white--text mt-6"
                    @click = "loginClicked"
                    :disabled="!isValid"
                  >Login
                  </v-btn>

                </div>
              
            </v-form>
          
            <div class="mt-5 d-flex align-center justify-center">
                <router-link class="black--text" 
                            :to="{name:'PasswordReset'}">
                            Forgot Password ?
                  </router-link>

            </div>
                
            <div class="mt-2 d-flex align-center justify-center">
                Click here to  &nbsp; 
                <router-link class="black--text"
                              :to="{name:'AccessRequest'}">
                              Request Access
                  </router-link>
            </div>

        </v-card>
            
      </div>
    
</template>

<script>

import axios           from "axios";
import { store }       from '../store/store'
import { apiResource } from '../store/apiResource'
import md5             from "js-md5";
import moment          from 'moment'

export default {  
  
      name       : 'SignOn',
 
      methods    : {

      loggedOnTimedEvents(){

            console.log( "LoggedOnTimerEvents");

            if( store.user ) {
     
               apiResource.getUserSubmissions();
               apiResource.getUserState();
               apiResource.getActivities();
               apiResource.getSettings(); 
   
               // 5-11-2024
               this.updateWindow();
           
            }

            /////////////////////////////////////////////////////////////////////////
            // recurring task thats runs every 60 seconds if the user has logged on//
            /////////////////////////////////////////////////////////////////////////
            if( store.user ) {
                setTimeout(() => { this.loggedOnTimedEvents(); },   1000*60);
            }

      },

      updateWindow              : function() {

            console.log( "updateWindow");
        
            const  baseUrl      =  apiResource.getResourceUrl( "window" );
            const  url          = `${baseUrl}`;
            axios.get( url)
            .then( response => {

                if ( ( response.data['status'] === 'success') ){

                        const window                       =  response.data['window']; 
                        const deadLine                     =  moment( window['deadLine'] );
                        store.deadLine                     =  deadLine.format('dddd, MMM Do YYYY');
                    
                        const weekEnding                   =  moment( window['weekEnding'] );
                        store.appDates['weekEndingISO']    =  weekEnding.format('YYYY-MM-DD');
                        store.appDates['weekEndingLong']   =  weekEnding.format('dddd, MMM Do YYYY');
           
                } else {
          
                        store.deadLine                     =  "submission window is closed";
                        store.appDates['weekEndingISO']    =  "submission window is closed";
                        store.appDates['weekEndingLong']   =  "submission window is closed";
                }

            })
            .catch( error => {
          
                  store.deadLine                     =  "submission window is closed";
                  store.appDates['weekEndingISO']    =  "submission window is closed";
                  store.appDates['weekEndingLong']   =  "submission window is closed";
          
                  this.store.toastDialog.text      = "updateWindow() Failed, please retry";
                  this.store.toastDialog.subtext   =  error.message;
                  this.store.toastDialog.show      =  true;
            });

      },

      loginClicked() {

          this.loading           =  true;
          const baseUrl          =  apiResource.getResourceUrl( "auth" );
          const url              =  `${baseUrl}/user`;
          const md5Password      =  md5(this.password); 
       
          ////////////////////////////////////////
          // 11-20-2023 add appVersion to json ///
          ////////////////////////////////////////

          const data = {  
                    "credential"      :  this.credential,
                    "password"        :  md5Password,
                    "appVersion"      :  apiResource.version
          } 
             

          axios.post(url, data).then( response => {
                       
            if ( response.data.status == 'success')
            {

                  console.log( "login successfull");

                  this.store.user = response.data.user;
                  
                  this.loggedOnTimedEvents();
                  
                  this.store.bottomNavigation = 0;
                  this.$router.push("/dashboard")
 
            } else {
              
                  console.log( "login failed");
                  this.$refs.signonForm.reset()
                  this.store.toastDialog.subtext   = "";
                  this.store.toastDialog.text  = "Login Failed, please retry";
                  this.store.toastDialog.show  = true;
            }
       
          }).catch(err => {
          
          
            this.$refs.signonForm.reset()
            this.store.toastDialog.text      = "Login Failed, please retry";
            this.store.toastDialog.subtext   = err.message;
            this.store.toastDialog.show      = true;
                 
          });

          this.loading                     = false;

      },



 
    },

    computed : {
        
        portmanPhone() {
          return apiResource.portman.phone;
        },
        appVersion() {
          return apiResource.version;
        }
    },


    data() {
      return {
          store,
          isValid           : false,
          value             : true,
          loading           : false,
          isPasswordVisible : false,
          credential        : null,
          password          : null,
       };
    },

}
</script>

import { store  } from '../store/store'
import axios      from "axios";

export const apiResource = {

    localDev                            : false,
    version                             : "4.28",
    portman                             : {
                                            phone           : "0161 818 2423", 
                                            declinedPhone   : "0161 828 4160",
                                          },

    localUrlbase  :  "http://localhost",
    liveUrlbase   :  "https://pwapi.portmanrecruitment.com",
    endpoint      :   {  

                        "auth"              : "/api/auth",
                        
                        "user"              : "/api/user",
                        "userstate"         : "/api/userstate",
                        
                        "submission"        : "/api/submission",
                        "activity"          : "/api/activity",
                        "setting"           : "/api/setting",
                                                
                        "passwordReset"     : "/api/passwordReset",
                        "accessRequest"     : "/api/requestAccess",
                        
                        "window"            : "/api/window",

                      },
  
    getResourceUrl          : function( resource ) {
        
        if (this.localDev) {
             return (  this.localUrlbase + this.endpoint[ resource] )
        } else {
             return (  this.liveUrlbase + this.endpoint[ resource] )
        }
    },

    getUserSubmissions       : function() {
        console.log( "getUserSubmissions");
        const  baseUrl      =  this.getResourceUrl( "submission" );
        const  url          = `${baseUrl}/${ store.user.id }`;
        axios.get( url).then( response => {
             if ( response.data.status ) {
                  store.submissions    = response.data.res;
            }
        });
        
    },

    getUserState             : function() {
        console.log( "getUserState");
        const  baseUrl      =  this.getResourceUrl( "userstate" );
        const  url          = `${baseUrl}/${ store.user.id }`;
        axios.get( url).then( response => {
            if ( response.data.status ) {
                 store.userState       = response.data.res;
            }
        });
    },

    getActivities            : function () {
        console.log( "getActivities");
        const  baseUrl      =  this.getResourceUrl( "activity" );
        const  url          = `${baseUrl}`;
        axios.get( url).then( response => {
            if ( response.data ) {
                 var activities    = response.data;
                 let hoursObj      = activities.find( (el) => el['activity'] === "hours");
                 store.isHoursOpen   = ( hoursObj.isOpen == 'yes') ? true : false;
            }
        });
        
    },

    getSettings              : function () {
        console.log( "getSettings");
        const  baseUrl      =  this.getResourceUrl( "setting" );
        const  url          = `${baseUrl}`;
        axios.get( url).then( response => {
            if ( response.data ) {
                 store.settings  = response.data;
            }
        });
        
    },
 
    //////////////////////////////////////////////////////////////////////////
    // 5/10/2024 the server will now generate 4.0.27
    // the deadline and also the weekEnding
    // 4.0.24 when the server cron jonb starts the submission window.
    //////////////////////////////////////////////////////////////////////////
    /*
        updateDeadline           : function() {

        // no longer used
        // thedeadLineis calculated on the server
        // 5-11-24    

        console.log("updateDeadline");

        var   now        = new Date();
        var   deadline   = new Date();
        let   locale     = "en-GB";
        const nowWeekday = now.toLocaleDateString(locale, { weekday: 'long' }).toLowerCase();

        switch(nowWeekday)
        {
          case 'wednesday':
               deadline.setDate( now.getDate() + 6 );
               break;
          case 'thursday':
               deadline.setDate( now.getDate() + 5 );
               break;
           case 'friday':
               deadline.setDate( now.getDate() + 4 );
               break;
          case 'saturday':
               deadline.setDate( now.getDate() + 3 );
               break;
          case 'sunday':
               deadline.setDate( now.getDate() + 2 );
               break;
          case 'monday':
               deadline.setDate( now.getDate() + 1 );
               break;
          case 'tuesday':{
               const hour = now.getHours();
               if ( hour > 12)
                   deadline.setDate( now.getDate() + 7 ); // next tuesday
                }
                break;
          }
        
          deadline.setHours(12);
          deadline.setMinutes(0);
          deadline.setSeconds(0);
          store.deadLine = deadline;
        },
        
        updateWeekEnding         : function() {
        // no longer used
        // the week ending is calculated on the server
        // 5-11-24    
        console.log( "updateWeekEnding");
        var newDate = moment(); 
        store.appDates['weekEndingISO']    = newDate.day( newDate.day() >= 5 ? 5 : -2).format('YYYY-MM-DD');
        store.appDates['weekEndingLong']   = newDate.day( newDate.day() >= 5 ? 5 : -2).format('dddd, MMM Do YYYY');
        },
    */

}
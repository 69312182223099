<template>

            <div class="dashedBorder">
                    <p class="text-center mb-1 pt-3"><strong>Current Status</strong></p>
                    <p class="text-center mb-1">Your last submission was declined</p>
                    
                    <p class="text-center mb-1"><strong>Last Submission</strong></p>
                    <p class="text-center mb-1">
                        <v-btn  class = "declinedFab"  elevation="0" fab rounded x-small></v-btn>
                        {{lastSubmissionDate}}
                   </p>


                    <p class="text-center mb-1"><strong>Get in Touch</strong></p>
                    <p class="text-center mb-1">Please phone 0161 828 4160</p>
                                       
                    <p class="text-center mb-4">
                        <router-link class="black--text" :to="{name:'Hours'}">{{ deadline }}</router-link>
                    </p>
                    
            </div>

</template>

<script>
     export default { 
        props : { lastSubmissionDate: String, deadline: String },
        name  : 'submissionDeclined'
    }
</script>  

<style scoped>

    .declinedFab {
            height : 16px;
            width  : 16px;
            background-color  : red !important;
    }

</style>

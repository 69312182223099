<template>

            <div class="dashedBorder">
                    <p class="text-center mb-1 pt-3"><strong>Current Status</strong></p>
                    <p class="text-center mb-1">Your submission is up to date</p>
                    <p class="text-center mb-1"><strong>Your last submission was accepted</strong></p>

                    <p class="text-center mb-1">
                        <v-btn  class = "acceptedFab"  elevation="0" fab rounded x-small></v-btn>
                        {{lastSubmissionDate}}
                   </p>
 
                   <p class="text-center mb-4">
                        <router-link class="black--text" :to="{name:'Hours'}">{{ deadline }}</router-link>
                   </p>
         
  
            </div>
 
</template>

<script>
   export default { 
      props : { lastSubmissionDate: String, deadline: String  },
      name  : 'submissionAccepted'
   }
</script>  

<style scoped>
  .acceptedFab {
            height : 16px;
            width  : 16px;
            background-color  :#1976d2 !important;
    }

</style>

<template>

      <v-dialog  v-model    = "store.toastDialog.show"
                  transition= "dialog-top-transition"
                  max-width = "400"
      >


      <v-card>
      
      <v-card-title    class="titleColor" >{{store.toastDialog.title}}</v-card-title>

            <v-divider></v-divider>

            <v-card-text     class="pt-2 text-center"><strong>{{store.toastDialog.subtext}}</strong></v-card-text>
            <v-card-text     class="text-center">{{store.toastDialog.text}} </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="justify-end">
              <v-btn 
                  color='#b9802b'
                  text
                  @click="store.toastDialog.show = false"
            >Close</v-btn>

            </v-card-actions>

      </v-card>
      
      
      </v-dialog>



</template>

<script>

    import { store }      from '../../store/store'
    export default {
      name: 'toastDialog',
      data () {
      return {  
          store,
      }
      },
  }

</script>

<style scoped>
.titleColor {
   background-color: #B9802B !important;
   color: white
}
</style>

<template>

            <div class="dashedBorder">
                    <p class="text-center mb-1 pt-3"><strong>Current Status</strong></p>
                    <p class="text-center mb-1 red--text">Your submission is overdue</p>
                    <p class="text-center mb-1"><strong>Last Submission</strong></p>
                    <p class="text-center mb-4">
                        <v-btn  class = "overdueFab"  elevation="0" fab rounded x-small></v-btn>
                        {{lastSubmissionDate}}
                   </p>

                   <p class="text-center mb-4">
                        <router-link class="black--text" :to="{name:'Hours'}">{{ deadline }}</router-link>
                   </p>


            </div>

</template>

<script>
   export default { 
        props : { lastSubmissionDate: String, deadline: String },
        name  : 'submissionOverdue'
   }
</script>  

<style scoped>
    .overdueFab {
            height : 16px;
            width  : 16px;
            background-color  :   transparent !important;
    }

</style>

<template>

      <div>

        <!-- 
             4.15 client does not want the confirmation dialog popup
             <hoursConfirmation :dialog="showHoursConfirmation" @hoursApproved="submitDataConfirmed($event)" /> 
        -->

        <v-dialog   v-model   = "exceptionDialog.showDialog"
                    transition      = "dialog-top-transition"
                    max-width       = "400"
                    @click:outside  = "exceptionDialogClose()" 
        >

            <v-card class="dashedBorder">
            
                  <p  class="red--text text-center mt-2"><strong>{{exceptionDialog.title}}</strong></p>

                  <v-divider></v-divider>

                    <div class="px-4">
                      <p class="text-center pt-2">{{exceptionDialog.subTitle}}</p>
                      <p class="text-center" v-html="exceptionDialog.text"></p>
                    </div>
            
                  <v-divider></v-divider>

                  <v-card-actions class="justify-end">
                  
                    <v-btn 
                        color='#b9802b'
                        text
                        @click="exceptionDialogClose"
                    >Ok</v-btn>
                  </v-card-actions>

            </v-card>
      
      
        </v-dialog>
         
         <v-card class="halfFillHeader" elevation = "0">

          <v-card-text class="d-flex white--text align-center justify-center viewTitle">
                Submit Hours
          </v-card-text>

          <div class="d-flex align-center justify-center">
                <v-btn style="background-color: white!important;" disabled elevation="0" fab class="portmanfab portmanLogo">
                <v-img
                   :src="require('@/assets/images/logos/portman.png')"
                    max-height = "80px"
                    contain
                ></v-img>
               </v-btn>
          </div>

          
         </v-card>
        
         <v-card elevation = "0" style="margin-top: -25px">

                  <div class="mt-2 mx-4 dashedBorder">
                      <div  class="red--text text-center py-2 px-2"><strong >Submission Deadline</strong></div>
                      <div  class="text-center pb-1 px-2">For payout this week, you must submit your {{ hoursOrDaysText }}, mileage & expenses by Tuesday @ Noon</div>
                      <div  class="text-center pb-1 px-2"><strong>Week Ending : {{ weekEndingLong }}</strong></div>
                      <div  class="text-center pb-1 px-2"><strong>DeadLine :    {{ formatDeadLine }}</strong></div>
                  </div>

                    
          <div class="mt-4 px-4">
         
          
            <v-form  :disabled = "disableForm" ref="submissionForm" v-model="isValid">
                      
              <div v-if="this.dayRateCandidate" class="d-flex align-baseline justify-center">
           
                <v-select 
                        label       =  "Days worked this week"
                        :items      =  "dayItems"
                        item-value  =  "id"
                        item-text   =  "value"
                        :rules      =  "dayRule"
                        v-model     =  "tmpDays" 
                        @change     =  "onChangeDays"
                        variant     =  "solo"
                        clearable
                        open-on-clear
                ></v-select>
     
              </div>

              <div v-else class="d-flex align-baseline justify-center">
             
             <v-row>

             <v-col class="pl-4" cols="4">
                <span>Enter Hrs/Mins: </span>
             </v-col>
                        
              <v-col cols="4">
                <v-text-field 
                 clearable
                 v-model        = "tmpHours" 
                 label          = "0 to 80 Hrs"
                 density        = "comfortable"
                 min            =  0
                 max            =  80
                 placeholder    = "0 to 80"
                 @input         = "onHoursInput"
                 @change        = "onChangeHours"
                 type           = "number"
                 :rules         = "[value => !!value || 'Required']"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  clearable
                  v-model      = "tmpMinutes"
                  label        = "0 to 59 Mins"
                  density      = "comfortable"
                  min          = 0
                  max          = 59
                  type         = "number"
                  placeholder  = "0 to 59"
                  @input       = "onMinutesInput"
                  @change      = "onChangeMinutes"
                  :rules       =  "[value =>  !!value || 'Required']"
                ></v-text-field>
              </v-col>

             </v-row>

              </div>


            <v-text-field
              label   = "Enter Mileage this week"
              clearable
              required
              v-model  = "mileage"
              :rules   = "mileageRule"
              type     = "number"
              min      = 0.00
              max      = 999.00
            ></v-text-field>

            <v-text-field
              clearable
              required
              :rules="expenseRule"
              v-model = "expenses"
              label="Enter expenses (in GBP max 499.99) this week"
              placeholder="Enter expenses (in GBP max 499.99) this week"
              type = "number"
            ></v-text-field>

            <!-- 4.15 @click was calling "submitData" which displayed the dialog-->
            <div class="d-flex align-center justify-center">
              <v-btn
                :loading   = "loading"
                :color     = "this.store.colors.darkGold"
                class      = "lightGoldBorder white--text"
                :disabled  = "!isValid"
                @click     = "submitDataConfirmed"
              >Submit Details 
              </v-btn>
            </div>

            </v-form>

          </div>              
       
          <div class="d-flex align-center justify-center">
            <v-card-text class="px-16">
              The submission will be verified and you will receive an e-mail notification
            </v-card-text>
          </div>

         </v-card>

      </div>

</template>

<script>

import { store        }          from '../store/store'
import { apiResource  }          from '../store/apiResource'

// 4.15 client does not want the dialog confirmation popup
// import  hoursConfirmation        from '@/components/Dialogs/hoursConfirmation.vue'

import  axios from "axios";

function getYears () {
  
  const currentYear = new Date().getFullYear();
  const years = [];
  
  for (let i = currentYear - 20; i < currentYear + 20; i++) {
    years.push({
      value: i.toString(),
      text: i.toString(),
    });
  }
  
  return years;
}


export default {

    name                          : 'Hours',
  //   4.15 client does not want the dialog confirmation popup
   // components                 : {Picker},
  
    mounted()  {
     
                    console.log("Hours Mounted")
                    apiResource.getActivities();

                    // are hours open
                    if ( this.hoursOpen == false){
                          this.exceptionDialog.title       = "Submission Hours";
                          this.exceptionDialog.subTitle    = "The submission window is currently closed.."; 
                          this.exceptionDialog.text        = "A submission can be sent between:<br/><b>Friday @ noon until Tuesday @ noon</b>"; 
                          this.exceptionDialog.showDialog  = true;
                          return;
                    }
                                        
                    // ok so hours are open//////////////////////
                    if ( this.hoursSubmitted == true){
                          this.exceptionDialog.title       = "Submission Hours";
                          this.exceptionDialog.subTitle    = "A submission has already been sent"; 
                          this.exceptionDialog.text        = "you last submitted on <br/><b>" + this.lastSubmissionDate + "<b>"; 
                          this.exceptionDialog.showDialog  = true;
                          return;
                    }

    },  

    beforeDestroy() {
                  console.log("Hours destroyed")
    },

    watch: {

        tmpHours (){
          if ( this.tmpHours > 80) {
               this.tmpHours  = ''
          }
        },

        tmpMinutes (){
          if ( this.tmpMinutes > 59) {
               this.tmpMinutes = ''
          }
        },

    },

    computed: {

          disableForm() {
             
            let result = false;

            if ( store.isHoursOpen == false ) {
                  result = true;
            }
             
            if (  store.deadLine  ==  "submission window is closed" ) {
                   result = true;
            }
            
            return result;

          },

          hourItems() {

            var hours = [];
            for (let i = 0; i < 50; i++) {
                  hours.push(`${i}`);
            }

            return ( hours);
          },
     
          weekEndingISO() {
            return this.store.appDates['weekEndingISO'];
          },

          weekEndingLong() {
            return this.store.appDates['weekEndingLong'];
          },

          hoursOpen() {
            return this.store.isHoursOpen;
          },

          hoursSubmitted(){
              let res = (this.store.userState.hoursSubmitted === 'yes') ? true : false;
              return res; //false;
          },

          formatDeadLine(){
              return this.store.deadLine;
          },

          lastSubmissionDate() {

            if (store.submissions.length === 0 ) { 
                return null;
            }

              let latestSubmission     = store.submissions[0];
              let date                 = new Date( latestSubmission["created_at"] );
              let intlDateObj          = new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'short', timeZone: 'Europe/London'});
              let gbTime               = intlDateObj.format(date);
              return gbTime;

          },

          dayRateCandidate(){
              let res = (this.store.user.dayRateCandidate) ? true : false;
              return res;
          },

          hoursOrDaysText(){
              let res = (this.store.user.dayRateCandidate) ? "days" : "hours";
              return res;
          },


    },
    

    methods    : {
  
      cleanText( text ) {

            let tmp      =  text.replace(/\D/g,'');
            let parsed   =  parseInt(tmp, 10);
            if ( isNaN(parsed) )
                 return tmp
            else
              return parsed

      },

      onHoursInput() {

            if ( ( this.tmpHours == null) ||  (this.tmpHours == '0') )
                   return;

            const cleanText = this.cleanText( this.tmpHours);
            this.$nextTick(() => {
               this.tmpHours = cleanText;
            })  
      },

      onMinutesInput() {

           if ( (this.tmpMinutes == null) ||  (this.tmpMinutes == '0') )
                 return;
   
           let cleanText = '';
           if ( this.tmpMinutes != '0') {
                 cleanText = this.cleanText( this.tmpMinutes);
           } 

           this.$nextTick(() => {
               this.tmpMinutes = cleanText;
            })  
      },
      
      onChangeHours() {
          this.hours = `${this.tmpHours}:${this.tmpMinutes}`
      },

      onChangeMinutes() {
          this.hours = `${this.tmpHours}:${this.tmpMinutes}`
      },

      onChangeDays() {
         this.hours   = `${this.tmpDays}:${this.tmpMinutes}`
      },

      exceptionDialogClose() {
               this.exceptionDialog.showDialog = false
               this.$router.push("/dashboard");
               this.store.bottomNavigation  = 0;
      },
      
      updateTimeRemaining( ) {
 
          if ( this.store.deadLine ) {
              const now               = new Date();
              this.timeRemaining      = this.store.deadLine - now; 
              setTimeout( this.updateTimeRemaining, 1000 );
          }
      },
 
      submitDataConfirmed() {

          // 4.15 client does not want the confirmation dialog popup
          // the submit click will now submit the record and not display the popup
          ////////////////////////////////////////////////////////////////////
          // close the confirmation dialog //
          ////////////////////////////////// 

          //  not needed 4.15
          //  this.showHoursConfirmation = false;
          //  if ( approved == false)
          //       return;
               
          //  4.21 11-13-2023
          //  do not allow 0.00 hours to be submitted
          //  if hours 0:00 do not allow submission
    

        

          if ( this.verifyHours( this.hours ) )
          {
         
              this.loading           =  true;
              const url              =  apiResource.getResourceUrl( "submission" );
              const submissionData   = {
                            userId      : this.store.user.id,
                            hours       : this.hours,
                            mileage     : this.mileage,
                            expenses    : this.expenses,
                            submitter   : "Candidate",
                            status      : "pending",
                            weekEnding  : this.weekEndingISO,
                            rollOver    : false,
              };
                        
              axios.post(url, submissionData)
               .then(  response => {
              
                   this.loading                 = false;
                   console.log(response.data);

                   // set the hoursSubmitted flag on the store
                   // will stop this user from multiple submission 
                   // the flag will be retrived from the server ast logon

                  // this.store.userState.hoursSubmitted  = "yes";
                  // this.store.userState.overdue         = "no";

                   // lets refresh this users submissions                 //
                   // and also the users state after the last submission  //
                   apiResource.getUserState();
                   apiResource.getUserSubmissions();
              
                   this.store.toastDialog.subtext   = "The submission has been accepted";
                   this.store.toastDialog.text      = "The submission will be verified and you will receive an e-mail notification";
                   this.store.toastDialog.show      = true;
                   
                   this.store.bottomNavigation  = 0;
                   this.$router.push("/dashboard")
                   
                  })
               .catch( error    => {
           
                    this.loading                     = false;
                    this.store.toastDialog.text      =  "User Submission Error";
                    this.store.toastDialog.subtext   =  error.response.data.message;
                    this.store.toastDialog.show      = true;
                });
          

          }

      },
   
      //   4.15 client does not want the confirmation dialog popup
      //   submitData() {
      //     this.showHoursConfirmation = true;
      //   }

      ///////////////////////////////////////////////
      // 4.21 do not allow 0:00 hours to be submitted
      ///////////////////////////////////////////////
      verifyHours ( hours ){
      
        if (  store.deadLine  ==  "submission window is closed" ) {
               this.store.toastDialog.subtext   = "User Submission Error";
               this.store.toastDialog.text      = "Submission window is currently Closed. Please check and re-submit, if this issue continues please let us know";
               this.store.toastDialog.show      = true;
               return false;
        }
    
        if (  store.appDates['weekEndingLong']  ==  "submission window is closed" ) {
               this.store.toastDialog.subtext    = "User Submission Error";
               this.store.toastDialog.text       = "Submission window is currently Closed. Please check and re-submit, if this issue continues please let us know";
               this.store.toastDialog.show       = true;
               return false;
        }

        //hours must be valid and minutes must be at least 1
        //hours is a string
        
        if ( hours === 0 ) {
               this.store.toastDialog.subtext   = "User Submission Error";
               this.store.toastDialog.text      = "We cannot accept Zero Hours. Please check and re-submit, if this issue continues please let us know";
               this.store.toastDialog.show      = true;
               return false;
        }


        if  (  
                     ( hours.length < 3  )                ||
                     ( hours.toLowerCase() === '00:0')    ||
                     ( hours.toLowerCase() === '00:00')   ||
                     ( hours.toLowerCase() === '0:0')     ||
                     ( hours.toLowerCase() === '0:00')
        ) {
               this.store.toastDialog.subtext   = "User Submission Error";
               this.store.toastDialog.text      = "We cannot accept Zero Hours. Please check and re-submit, if this issue continues please let us know";
               this.store.toastDialog.show      = true;
               return false;
 
        } else {
              return  true;
        }
   
        
      }
      
    },

    data() {
      const current = (new Date().getFullYear()).toString();
      return {
          store,
          isValid                   : false,
          loading                   : false,
          
          tmpHours                  :  '0',
          tmpMinutes                :  '0',
          tmpDays                   :  '0',

          hours                     : "0:00",
          daysWorked                : "0",
          mileage                   : "0",
          expenses                  : "0.00",
          timeRemaining             : null,
          showHoursConfirmation     : false,
          minuteSlider              : { min : 1, max: 60},
          hourSlider                : { min : 0, max: 60},

          years: getYears(),
          currentYear: {
            value: current,
            text: current,
          },

     
          rules :  {
                    required    : value =>  !!value                       || 'Required.',
                    length2     : value =>  value.length <= 2             || 'Max 2 Characters',
                    numericRule : value =>  /^[0-9]*$/.test(value)        || 'Input must be a number',
                    positiveInt : value =>  /^[0-9]*$/.test(value)        || 'Input must be numeric',
          },

          minuteItems               : [
                                         { text : '1/4 Hour',  value : '15'},
                                         { text : '1/2 Hour',  value : '30'},
                                         { text : '3/4 Hour',  value : '45'},
                                      ],

          mileageRule : [

            function(v) {
                  
                  if (!v || v.length < 0) {
                     return 'Mileage must be between 0 to 999.99';
                  } else if ( v > 999.99 ) {
                     return  'Maximum Mileage is 999.99';
                  } else if ( v < 0.00 ) {
                     return  'Minimum Mileage is 0';
                  }else
                     return true;
            }  
   
          ],

          expenseRule : [

              function(v) {
          
                if (!v || v.length < 0) {
                  return 'Expenses are required (use 0.00 if none)';
                } else if ( v > 499.99 ) {
                   return 'Maximum Expenses are 499.99';
                } else if ( v < 0.00 ) {
                   return 'Minimum Expenses are 0.00';
                }else
                   return true;
              }  

          ],
          
          minuteRule: [
                
                function(v) {
                  if ( !v || v.length < 0 ){
                      return false;
                  }else if ( v.length > 2) {
                      return false
                  }else if ( v.includes('.')) {
                     return( false);
                  }else if ( ( v > 60 ) || ( v < 1 ) ) {
                     return( false);
                  } else
                    return true;

                }
          ],

          daysRule: [
                function(v) {
                 
                  if (!v || v.length < 0) {
                     return false
                  } else if ( v == null ){
                    return false;
                  } else if ( v > 0){
                    return true
                  }

                }
          ],

          dayItems : [
            { id: 0, value: "0"},
            { id: 1, value: "1"},
            { id: 2, value: "2"},
            { id: 3, value: "3"},
            { id: 4, value: "4"},
            { id: 5, value: "5"},
            { id: 6, value: "6"},
            { id: 7, value: "7"},
          ],

          exceptionDialog           : {
                                          showDialog : false,
                                          title      : "",
                                          subTitle   : "",
                                          text       : "",  
                                      },

      };
    },

}

</script>


<style>

  .whitetext    { color: white !important; }
  .centerText   { text-align:center   }
  
</style>
<template>

      <div>

      
        <v-card class="halfFillHeader" elevation = "0">

          <v-card-text class="d-flex white--text align-center justify-center viewTitle">
                Submission History
          </v-card-text>

          <div class="d-flex align-center justify-center">
               <v-btn style="background-color: white!important;" disabled elevation="0" fab class="portmanfab portmanLogo">
                   <v-img
                      :src="require('@/assets/images/logos/portman.png')"
                      max-height = "80px"
                      contain
                  ></v-img>
             </v-btn>
          </div>
   
          <v-btn v-if = false :ripple="false"
           
                   icon
                   absolute
                   bottom
                   left
                  :loading  = "store.refreshLoading"
                  @click    = "historyRefresh"
              >
              <v-icon large >mdi-refresh</v-icon>
          </v-btn>

        </v-card>
     
        <v-card v-if= "this.store.user" elevation = "0" class="up25">
       
        
          <v-card-text class="d-flex justify-center">
            
            <v-list v-if="this.store.submissions.length > 0" class="overflow-y-auto historyDashedBorder" :height="computedlistHeight">
                
              <v-list-item   v-for="(item, index) in this.store.submissions" :key="index">
                  
                    <v-list-item-content>

                          <v-row class='align-center' id="h1">
                          <v-col cols="1">
                            <v-btn  class      = "fabSize"
                                   :color      = "fabColor [item.status]"
                                    elevation  = "2"
                                    fab
                                    rounded>
                                    {{ fabLetter[item.status] }}
                            </v-btn>

                          </v-col>
                          <v-col cols="11">
                             <v-list-item-title class="pl-2">    {{ formatCreatedDate( item )  }} </v-list-item-title>
                          </v-col>
                          </v-row>

                          <v-list-item-subtitle> 
                                                <span class="lightGold">{{hoursOrDaysText}}:</span>                            
                                                {{ item.hours }} 
                                             
                                                <span class="lightGold">Mileage:</span>                            
                                                {{ item.mileage }} 
                                             
                                                <span class="lightGold">Expenses:</span>                            
                                                {{ item.expenses }} 
                          </v-list-item-subtitle>
                      
                        <v-divider class="mt-3"></v-divider>
              
                    </v-list-item-content>
  
              </v-list-item>
 
            </v-list>


          </v-card-text>

        </v-card>
        
      </div>

</template>

<script>

import { store       }  from '@/store/store'
import { apiResource }  from '../store/apiResource'

export default {

    name       : 'History',
    mounted()  {
                  console.log("History mounted")
    },  
    beforeDestroy() {
                  console.log("History before destroy")
    },
  
    computed : {

        hoursOrDaysText(){
              return (this.store.user.dayRateCandidate) ? "Days" : "Hours";
        },

      computedlistHeight : function (){ 
           let maxListHeight  = store.screenSize.height - 300;
           return ( maxListHeight)
      },

    },

    methods         : {
    

      formatCreatedDate( item ) {

          let date         = new Date( item.created_at );
          let intlDateObj  = new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'short', timeZone: 'Europe/London'});
          let gbTime      = intlDateObj.format(date);
          return  gbTime;

      },
      
      formatDate(item){
            const dt      = new Date(item.created_at);
       //     const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
            const month   = ["January","February","March","April","May","June","July","August","September","October","November","December"];

         //   let day   = weekday[dt.getDay()];
            let m     = month[dt.getMonth()];
            let d     = dt.getDate();
            let y     = dt.getFullYear();
            let h     = dt.getHours();
            let mins   = dt.getMinutes();

            let temp = `${m} ${d}, ${y} @ ${h}:${mins}`
            return temp;
      },

      historyRefresh() {
         this.store.refreshLoading = true;
         apiResource.getUserSubmissions();
         apiResource.getUserOverdue();
      }
  
    },

   
    data() {
        return {
          store,

          fabColor : {
                        pending   : "orange",
                        declined  : "danger",
                        accepted  : "primary",
                        approved  : "success",
                        archived  : "success",
                     },
          fabLetter : {
                        pending   : "P",
                        declined  : "D",
                        accepted  : "A",
                        approved  : "Ap",
                        archived  : "Ar"
                     }


        };
    },

}

</script>


<style scoped>

    .lightGold {
                color : #DFC049 !important;
    } 
    .fabSize {
            height : 28px;
            width  : 28px;
    }

    .fabPosition {
      position: relative;
      bottom: 8px;
      margin-right: 10px;
    }
       

</style>





<template>

  <v-app >

    <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
     An update is available
      <v-btn text @click="refreshApp">
        Update
      </v-btn>
    </v-snackbar>

    <toastDialog />
        
        
     <v-app-bar v-if = false flat app :color="this.store.colors.darkGold"> 
        <v-app-bar-nav-icon v-if = "this.store.user" color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-spacer />
        <v-toolbar-title class="text-center white--text">
           {{  currentRouteName }}
        </v-toolbar-title>
        <v-spacer />
     </v-app-bar>

     <v-navigation-drawer v-if = false app v-model="drawer">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6"> Portman Hours</v-list-item-title>
          <v-list-item-subtitle> PortMan Recruitment</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>

      <v-list dense nav>
        
        <v-list-item v-for="item in items" :key="item.title" link @click.stop = "drawerClick( item )">

          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
             <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>

      </v-list>
     </v-navigation-drawer>
  

     <v-main>
        <v-container fluid>
            <router-view/>
        </v-container>
     </v-main>
     
     <v-bottom-navigation 
          :value="this.store.bottomNavigation"
           dark
           class="bottomNavBar"
          :background-color= "this.store.colors.darkGold"
           app bottom fixed>
                               
      <v-btn class="bottomNavButton" v-if = "this.store.user" @click = "homeClicked">
         <span>Home</span>
         <v-icon>mdi-home</v-icon>
      </v-btn>

      <v-btn class="bottomNavButton" v-if = "this.store.user" @click = "hoursClicked" >
        <span>Hours</span>
        <v-icon>mdi-cloud-upload</v-icon>
      </v-btn>

      <v-btn class="bottomNavButton" v-if = "this.store.user" @click = "historyClicked" >
        <span>History</span>
         <v-icon>mdi-history</v-icon>
      </v-btn>
      
      <v-btn class="bottomNavButton" v-if = "this.store.user" @click = "logoutClicked" >
           <span>Log Out</span>
           <v-icon>mdi-logout</v-icon>
      </v-btn>

    </v-bottom-navigation>
    
  </v-app>

</template>

<script>

import { store }       from  './store/store'
import { apiResource } from  './store/apiResource'
import update           from '@/mixins/update'


import toastDialog                  from './components/Dialogs/toastDialog.vue';

export default {
  
  name                        : 'App',
  components                  : { toastDialog},
 
  mounted() {

      this.store.screenSize.width  = document.documentElement.clientWidth;
      this.store.screenSize.height = document.documentElement.clientHeight;

      window.addEventListener('resize', this.getDimensions);
      setTimeout(() => { this.timedUpdateApp(); },  1000*60)

      this.appInit();
      
  },
  
  unmounted() {
        window.removeEventListener('resize', this.getDimensions);
  },

  computed   : {

      portmanPhone() {
          return apiResource.portman.phone;
      },
      appVersion() {
          return apiResource.version;
      },
      currentRouteName() {

      const  appBarTitle         = { 
                                      signon          : ( apiResource.version)    ?  `Portman Hours Ver: ${apiResource.version}`  : null,
                                      dashboard       : ( store.user )    ?   `Welcome Back ${store.user.fullName}`  : null,
                                      hours           : ( store.user )    ?   `Submit Hours`                         : null,
                                      history         : ( store.user )    ?   `Submission History`                   : null,
                                      passwordreset   : `Lost Login`,
                                      accessrequest   : `Request Access`,
                                   }

      const  currentRoute        = this.$route.name.toLowerCase();
      return appBarTitle[ currentRoute ];

      }
  },
  methods : {

    /////////////////////////////////////////////
    // sets the date for workWeekEnding in iso //
    /////////////////////////////////////////////
    appInit() {
   
   
       //   alert("appInit()");
       //   ( isLoggedOn ) ? alert("a user is logged on") : alert("user is not logged on"); 

          const isLoggedOn = ( store.user ) ? true : false;
          if ( (isLoggedOn === false) && (this.$router.currentRoute.path != '/') ){
               this.$router.push( "/" );
          }
    },

    
    timedUpdateApp : async function(){
            
           console.log("timedUpdateapp");

            var registration    = await navigator.serviceWorker.getRegistration();
            var updated         = await registration.update();
            setTimeout(() => { this.timedUpdateApp(); },         1000*60)
            return updated;
    },


    drawerClick( item ) {

      this.store.bottomNavigation = item.navIdx;
      this.$router.push( item.route );

      if ( item.route === "/") {
        this.store.user = null;
      }


    },

    getDimensions() {
         this.store.screenSize.width  = document.documentElement.clientWidth;
         this.store.screenSize.height = document.documentElement.clientHeight;
    },

    logoutClicked() {
        this.store.user = null;
        this.$router.push("/")
    },

     homeClicked() {
         
        if( this.$router.currentRoute.path != '/dashboard') {
          this.$router.push("/dashboard")
         }

      },

      hoursClicked() {
         if (this.$router.currentRoute.path != '/hours') {
          this.$router.push("/hours")
         }
      },

      historyClicked() {
        if (this.$router.currentRoute.path != '/history') {
         this.$router.push("/history")
        }
      }

  },

  data: () => ({
    store,
    drawer : false,
    items: [
      { title: 'Home',     navIdx: 0,  route: '/dashboard',  icon: 'mdi-view-dashboard' },
      { title: 'Hours',    navIdx: 1,  route: '/hours',      icon: 'mdi-cloud-upload' },
      { title: 'History',  navIdx: 2,  route: '/history',    icon: 'mdi-history' },
      { title: 'Logout',   navIdx: 3,  route: '/',           icon: 'mdi-logout' }
    ],

  }),
  mixins: [update],

};
</script>


<style>

.container--fluid { padding: 0px !important;}

.v-card { 
          border-radius: 0px !important;
          box-shadow:    0px !important;
        }

body  { overscroll-behavior: contain; }
html  { overscroll-behavior: contain; }


.portmanLogo {
      height: 140px !important;
      width: 140px !important;
      background-color: white !important;
}

.halfFillHeader {
      background: linear-gradient(180deg, #B9802B 65%, #FFFFFF 35%) !important;
}

.bottomNavBar{
  padding-top: 5px !important;
}

.bottomNavButton{
  background-color: transparent !important;
}

.lightGoldBorder {
    border-color: #DFC049     !important;
    border: 1px solid #DFC049 !important;
}

.darkGoldBorder {
    border-color: #B9802B !important;
    border: 1px solid #B9802B !important;
}

.removeBoxShadow {
  box-shadow: none !important;
}

.historyDashedBorder { 
                     border: dashed 2px #D9d9d9 !important;
                     border-radius: 10px !important;
}

.dashedBorder { 
                border: dashed 4px #DFC049 !important;
                border-radius: 15px !important;
              }

.up25 {
   margin-top: -25px !important;
}


.portmanfab {
     color : white;
     background-color: white!important;
}

.portmanfab::before {
     display: none;
}

.viewTitle {
  font-family: 'Roboto';
  font-size: 20px;
}

.bottomNavBar {
  padding-top: 5px !important;
}

</style>



import Vue from 'vue'

import VueRouter      from 'vue-router'
import SignOn         from '../views/SignOn.vue'
import Dashboard      from '../views/Dashboard.vue'
import PasswordReset  from '../views/PasswordReset.vue'
import AccessRequest  from '../views/AccessRequest.vue'
import History        from '../views/History.vue'
import Hours          from '../views/Hours.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SignOn',
    component: SignOn,
    meta: {
      requireAuth: false
    }
  },{
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requireAuth: true
    }
  },{
    path: '/passwordReset',
    name: 'PasswordReset',
    component: PasswordReset,
    meta: {
      requireAuth: false
    }
  },{
    path: '/accessRequest',
    name: 'AccessRequest',
    component: AccessRequest,
    meta: {
      requireAuth: false
    }
  },{
    path: '/history',
    name: 'History',
    component: History,
    meta: {
      requireAuth: true
    }
  },{
    path: '/hours',
    name: 'Hours',
    component: Hours,
    meta: {
      requireAuth: true
    }
  }

]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


export default router
